/**
 *
 * Custom function
 *
 */

(function ($) {

    /*
     *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
     */

    function allClick() {
        var clickbox = $('.allclick');
        clickbox.each(function () {
            var $this = $(this);

			// se il selettore non contiene link, rimuovo la classe allclick passo agli atri
			if( $this.find('a').length < 1 ) {
				$this.removeClass('allclick');
				return;
			}

            $this.css('cursor', 'pointer');
            $this.find('a').click(function (event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function () {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function () {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    /*
     *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
     */

    function loadAsynchronousImage() {

        $('*[data-loadimg]').each(function () {
            var box = $(this),
                src = box.attr('data-loadimg'),
                alt = '',
                img = new Image();

            if (box.attr('data-alt')) {
                alt = box.attr('data-alt');
            }

            $(img).load(function () {
                box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
                $('img', box).hide().fadeIn();

            }).attr('src', src);
        });

        $('*[data-loadbackground]').each(function () {
            var box = $(this),
                src = box.attr('data-loadbackground');

            box.css({
                'background-image': 'url(' + src + ')',
                'background-repeat': 'no-repeat',
                'background-position': 'top center'
            });

            if (box.has(['data-position'])) {
                box.css({
                    'background-position': box.attr('data-position'),
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            } else {
                box.css({
                    'background-position': 'top center',
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            }

            if (box.attr('data-repeat') == 'repeat') {
                box.css({
                    'background-repeat': 'repeat'
                });
            } else {
                box.css({
                    'background-repeat': 'no-repeat'
                });
            }
        });
    }


    /*
     *	Funzione per la gestione dei bottoni "condividi" legati ai social
     */

    function socialOpen() {
        $('[data-socialurl]').each(function () {
            var $this = $(this),
                url = $this.attr('data-socialurl');

            $this.click(function () {
                window.open(url, '', 'width=500, height=500');
            });

        });
    }

    function trackAdwordsCampaign() {
        //$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
    }


    /*
     *	Funzione per l'apertura di una finestra modale tramite cookie
     */

    function openModal() {
        if ($('#onloadmodal').length) {
            if (!$.cookie("openmodal")) {
                $('#onloadmodal').modal();
                $.cookie("openmodal", 'no', {
                    path: '/'
                });
            }
        }
    }



    /*
     *	Funzione per l'apertura di un iframe all'interno di una finestra modale
     */

    function iframeModalOpen() {
        $('.videoModal').each(function () {
            // impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
            $('.modalButton').on('click', function (e) {
                var src = $(this).attr('data-src');
                var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
                var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

                var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

                // stampiamo i nostri dati nell'iframe
                $(".videoModal iframe").attr({
                    'src': src,
                    'height': height,
                    'width': width,
                    'allowfullscreen': ''
                });
            });

            // se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
            $(this).on('hidden.bs.modal', function () {
                $(this).find('iframe').html("");
                $(this).find('iframe').attr("src", "");
            });
        });
    }

    /*
     *	Funzione per la formattazione delle tabelle con classe listino
     *	tramite le classi di bootstrap
     */

    function responsiveTable() {

        $('.entry-content table').each(function () {
            var $this = $(this);
            $this.addClass('table');
            $this.wrap('<div class="table-responsive"></div>');
        });

        if ($(window).width() <= 767) {

            $('#primary .table-responsive').prepend(
                '<span class="angle-scroll-left">' +
                '<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
                '</span>'
            );

            $('#primary .table-responsive').on('scroll', function (event) {
                var angleScrollLeft = $('.angle-scroll-left');

                if ($(this).scrollLeft() >= 1) {
                    angleScrollLeft.addClass('fade-out');
                } else {
                    angleScrollLeft.removeClass('fade-out');
                }
            });

            $('#primary').find('.table-responsive').each(function () {
                var table = $(this);
                $('.angle-scroll-left').click(function (event) {
                    table.animate({
                        scrollLeft: '+=60'
                    }, 200);
                });
            });
        }
    }

    /*
     *	Funzione per l'inizializzazione della mappa
     */
    function initializeGmap() {
        $('*[data-location]').each(function () {
            var map = $(this),
                address = map.attr('data-location');
            map.setGMap({
                address: address
            });
        });
    }


    /**
     * Funzione per l'attivazione del menu responsive
     */

	 /*
    function responsiveMenu(viewportSize) {
        $('.main-navigation').find('a[href="#"]').each(function () {
            $(this).click(function (event) {
                event.preventDefault();
            });
        });

        
        var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");
        var fixedMenu = $('#js-fixed-menu')[0];
        var layerMenu = $('.layer-menu');


        if (!fixedMenu) return;

        var stickyHeader = new Waypoint.Sticky({
            element: fixedMenu
        });

        $('.main-navigation').find('a[href="#"]').each(function () {
            $(this).click(function (event) {
                $('ul.sub-menu').removeClass('open');
                $(this).next().toggleClass('open');
            });
        });

        $("#js-menu-offcanvas-button-open").click(function () {
            var tl = new TimelineMax();

            tl.to(layerMenu, 0.3, {
                autoAlpha: 0.5, ease: Power2.easeOut, onComplete: function () {
                    menuPrimaryOffcanvas.addClass("offcanvas-open");
                }
            });

            tl.staggerFromTo(menuPrimaryOffcanvas.find('.menu-item a, .gsap-stagger-menu, .gsap-stagger-form-request'), 0.5, { y: -40, opacity: 0 }, { y: 0, opacity: 1, ease: Back.easeOut.config(1.2), delay: 0.2 }, 0.1);
            
            return tl;
        });

        $("#js-menu-offcanvas-button-close, .layer-menu").click(function () {

            TweenMax.to(layerMenu, 0.3, { autoAlpha: 0, ease: Power2.easeOut });
            menuPrimaryOffcanvas.removeClass("offcanvas-open");
        });

    } */

	function responsiveMenu(viewportSize) {
        $('#site-navigation').find('a[href="#"]').each(function () {
            $(this).click(function (event) {
                event.preventDefault();
            });
        });
        
        $('#site-navigation').find('a[href="#"]').each(function () {
            $(this).click(function (event) {
                $('ul.sub-menu').removeClass('open');
                $(this).next().toggleClass('open');
            });
        });
    }

    /*
     *  Funzioni per PhotoSwiper
     */
    function addImageSizeForPhotoSwiper() {
        var thumbs = $('img[data-big-size]');
        thumbs.each(function (index) {
            if ($(this).parent('a').length > 0) {
                var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
                if (isImageLink) {
                    big_size = $(this).data('big-size');
                    link = $(this).parent('a');
                    link.attr('data-size', big_size);
                    link.wrap('<div class="single-image"></div>');
                }
            }
        });
    }

    function initPhotoSwiper(selector) {
        var galleryItems = $(selector);
        var pswpItems = [];
        galleryItems.each(function (index) {
            var dimensions = $(this).find('a').data('size').split('x');
            var width = parseInt(dimensions[0], 10);
            var height = parseInt(dimensions[1], 10);

            myImage = {
                src: $(this).find('a').attr('href'),
                w: width,
                h: height,
                msrc: $(this).find('img').attr('src')
            };

            pswpItems.push(myImage);
        });


        galleryItems.each(function (index) {
            $(this).click(function (evt) {
                evt.preventDefault();
                openPhotoSwipe(pswpItems, index);
            });
        });
    }

    function openPhotoSwipe(galleryItems, index) {
        var options = {
            index: index
        };
        var pswpElement = $('.pswp')[0];

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
        gallery.init();
    }


    function smoothScroll() {
        $("a[href*='#']").not("[href='#'],[data-toggle]").click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {

                    if (target.attr('data-paddingTopScroll')) {
                        var adjust = target.attr('data-paddingTopScroll');
                    }
                    else {
                        var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
                    }

                    $('html, body').animate({
                        scrollTop: target.offset().top - adjust
                    }, 1000);

                    return false;
                }
            }
        });
    }

    function startOwl(selector) {
        $(selector).each(function (arguments) {
            var owl = $(this);
            /*
            console.log(owl);
            if(owl.hasClass('offers-slideshow') ){
                console.log('adfasdfasdf');
            }
            */
            var params = {
                nav: owl.data('owl-navigation'),
                navSpeed: owl.data('owl-slide-speed'),
                autoplay: owl.data('owl-autoplay'),
                autoplayTimeout: owl.data('owl-autoplay-timeout'),
                autoplaySpeed: owl.data('owl-autoplay-speed'),
                dots: owl.data('owl-dots'),
                dotsSpeed: owl.data('owl-dots-speed'),
                loop: owl.data('owl-loop'),
                autoHeight: false,
                lazyLoad: true,

                navText: [
                    "<i class='icon-edt-arrow-left-2'></i>",
                    "<i class='icon-edt-arrow-right-2'></i>"
                ]
            };

            if (owl.data('owl-slide-padding')) {
                params['margin'] = 10;
                params['stagePadding'] = 150;
            }

            if (owl.data('owl-single-item')) {
                params['items'] = 1;
            } else {
                if (owl.hasClass('offers-slideshow')) {

                    params['responsive'] = {
                        0: {
                            items: owl.data('owl-items-xs'),
                        },
                        768: {
                            items: owl.data('owl-items-sm'),

                        },
                        992: {
                            items: owl.data('owl-items-md'),
                        },
                        1200: {
                            items: owl.data('owl-items-lg'),
                        },
                        1440: {
                            items: owl.data('owl-items-xl'),
                        }
                    };

                } else if (owl.hasClass('gallerySlideshow')) {

                    params['responsive'] = {
                        0: {
                            items: owl.data('owl-items-xs'),
                            center: true,
                        },
                        768: {
                            items: owl.data('owl-items-sm'),
                            margin: 10,
                        },
                        992: {
                            items: owl.data('owl-items-md'),
                        },
                        1200: {
                            items: owl.data('owl-items-lg'),
                        },
                        1440: {
                            items: owl.data('owl-items-xl'),
                        }
                    };

                } else if (owl.hasClass('gallery-slideshow')) {

                    params['responsive'] = {
                        0: {
                            items: owl.data('owl-items-xs'),
                        },
                        768: {
                            items: owl.data('owl-items-sm'),
                        },
                        992: {
                            items: owl.data('owl-items-md'),
                        },
                        1200: {
                            items: owl.data('owl-items-lg'),
                        },
                        1440: {
                            items: owl.data('owl-items-xl'),
                        }
                    };

                } else {

                    params['responsive'] = {
                        0: {
                            items: owl.data('owl-items-xs'),

                            center: true,

                        },
                        768: {
                            items: owl.data('owl-items-sm'),
                            center: true,
                            margin: 10,
                            stagePadding: 150,
                        },
                        992: {
                            items: owl.data('owl-items-md'),
                        },
                        1200: {
                            items: owl.data('owl-items-lg'),
                        },
                        1440: {
                            items: owl.data('owl-items-xl'),
                        }
                    };
                }

            }

            owl.owlCarousel(params);

            if (owl.data('owl-prev')) {
                $(owl.data('owl-prev')).click(function () {
                    owl.trigger('prev.owl.carousel');
                });
            }
            if (owl.data('owl-next')) {
                $(owl.data('owl-next')).click(function () {
                    owl.trigger('next.owl.carousel');
                });
            }
        });
    }

    /*****************************************************************************************/

    function toConsole(string) {
        if ((typeof window.console == "undefined")) {
            alert(string);
        } else console.log(string);
    }

    function $j(argument) {
        var result = jQuery(argument);
        if (result.length === 0) return null;
        else return result;
    }

    function translateCountdown(langCountdown) {

        countdown.resetLabels();

        if (langCountdown == 'it') {
            countdown.setLabels(
                ' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
                ' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
                ' e ',
                ' '
            );
        } else if (langCountdown == 'de') {
            countdown.setLabels(
                ' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
                ' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
                ' und ',
                ' '
            );
        } else if (langCountdown == 'fr') {
            countdown.setLabels(
                ' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
                ' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
                ' et ',
                ' '
            );
        }

    }

    function edtCountdown() {

        jQuery(".edt_countdown_class").find('.item').each(function () {

            var dataInizio = "";
            var questo = jQuery(this);
            jQuery.post(
                url.ajax_url,
                {
                    'action': 'edita_ajax_get_start_countdown_date',
                    'offerId': questo.data('offerid'),
                },

                function (response) {
                    setInterval(function () {
                        stampaCountdown(questo, response);
                    }, 3600);
                }
            );
        });
    }

    function stampaCountdown(item, response) {

        jQuery(item).find('.pageTimer').empty().append(
            moment().countdown(response, countdown.DAYS | countdown.HOURS).toString()
        );
    }

    function closePhotoswipe() {

        $('.pswp__button--close').click(function () {
            if ($('.pswp').hasClass('pswp--open')) {
                $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
            }
        });

        $(window).scroll(function () {
            if ($('.pswp').hasClass('pswp--open')) {
                $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
            }
        });
    }

    /*
	 * Funzione per utilizzare MixItUp da select
	 */

    function filterElements() {

        var filterSelect = '.select-offers',
            container = '.mix-filter-support';

        if ($(container).length) {
            var mixer = mixitup(container);

            $(filterSelect).on('change', function () {
                mixer.filter(this.value);
            });
        }
    }

    /**
     * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
     * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
     * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
     * ritorna un boleano.
     */
    var mapsMobileUrl = {
        isAndroid: function () {
            return /(android)/i.test(navigator.userAgent);
        },
        isIos: function () {
            return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
        }
    };


	/**
	 * Funzione per nascondere e visualizzare la testata allo scroll della pagina
	 */

    function scrollHeader() {
        $('#js-header').scrollHide({
            scrollDelta: 1,
            scrollOffset: 10,
            scrollClass: 'scrh-header',
            scrollClassHide: 'scrh-hide',
            onScroll: function (context, msg, currentTop) {
                if (currentTop !== 0) {
                    $('.menu-offcanvas').removeClass('offcanvas-open');
                    $('#js-header').addClass('scrollup');
                    $('.payoff').css('z-index', '10');
                    $('.header-slideshow .reviews').css('z-index', '10');

/*
                    if (!$('body').hasClass('is-mobile')) {
                        $('.logo-item').attr('src', 'https://hotel-derby.com/wp-content/themes/edita-custom-2/images/logo-small.png');
                    }
*/

                }

                if (currentTop === 0) {
                    $('.menu-offcanvas').removeClass('offcanvas-open');
                    $('#js-header').removeClass('scrollup');
                    $('.payoff').css('z-index', '2000');

/*
                    if (!$('body').hasClass('is-mobile')) {
                        $('.logo-item').attr('src', 'https://hotel-derby.com/wp-content/themes/edita-custom-2/images/logo.png');
                    }
*/
                }
            }
        });
    }


    function injectorLinkMaps() {
        var buttonMaps = $('.navigator');

        //if (mapsMobileUrl.isAndroid()) buttonMaps.attr('href', phpMapsMobileUrl.android);
		
		if (mapsMobileUrl.isAndroid()) buttonMaps.attr('href', 'http://maps.google.com/maps/?daddr=Traversa%20II,%2027,%2048015%20Milano%20Marittima%20RA')
		
        if (mapsMobileUrl.isIos()) buttonMaps.attr('href', phpMapsMobileUrl.ios);

        if (!mapsMobileUrl.isAndroid() && !mapsMobileUrl.isIos()) buttonMaps.hide();
    }

    /**
         * Funzione per l'attivazione del form
        */
    function toggleForm(viewportSize) {
        var formWrapper = $(".efp-form-wrapper");
        var formContatti = $(".edita-form-builder");
        var formFake = $('#form-placeholder');
        var buttonClose = $('#button-close');
        var infoBooking = $('.container-info-booking');
        var infoBookingH = infoBooking.height();
        var formContattiH = formContatti.find('.row').height();
        var layerMenu = $('.layer-menu');
        var tl = new TimelineMax();

        infoBooking.height(0);

        var formInnerClick = function (event) {

            tl.to(layerMenu, 0.3, { autoAlpha: 0.5, ease: Power2.easeOut });

            layerMenu.css({ 'z-index': 50 });

            formFake.addClass("fake-open");

            formContatti.css({ "min-height": '100%', "overflow": 'initial' }).animate({ height: formContattiH }, 500);

            buttonClose.css('opacity', '1');

            $('.efp-popup-container').css('opacity', '0');

            setTimeout(function () {
                infoBooking.css({ display: 'block' }).animate({ height: infoBookingH }, 500);
            }, 100);

            formFake.addClass("fake-close");

            $(this).off();
        };

        $('.efp-form-inner').click(formInnerClick);

        $(".closePopupForm").click(function () {

            layerMenu.css({ 'z-index': 300 });

            formContatti.css({ "min-height": 0, "overflow": 'hidden' });

            formContatti.animate({
                height: '0px'
            }, 500, 'linear', function () {
                formWrapper.removeClass("fake-open");
                formFake.removeClass("fake-close");
            });

            infoBooking.animate({
                height: '0px'
            }, 500, 'linear', function () {
                $(this).css({ display: 'none' })
            });

            buttonClose.css('opacity', '0');

            TweenMax.to(layerMenu, 0.3, { autoAlpha: 0, ease: Power2.easeOut });

            setTimeout(function () {
                $('.efp-popup-container').css('opacity', '1');

                $('.efp-form-inner').click(formInnerClick);
            }, 300);
        });

    }



    function headerAnimation() {
        // animazione payoff
        TweenMax.staggerFromTo($('.gsap-payoff-stagger'), 0.8, { opacity: 0, y: -80 }, { opacity: 1, y: 0, ease: Back.easeOut.config(0.2) }, 1.1);

        // effetto pacchetto regalo
        if (!$('body').hasClass('.mobile')) {
            $(window).scroll(function () {
                if ($(window).scrollTop() >= 20 && $(window).scrollTop() <= 60) {
                    var el = $('#js-buttonHead-gift i'),
                        newone = el.clone(true);
                    el.before(newone);
                    el.last().remove();
                }
            });
        }
    }

    // funzione per l'aggiunta dell'effetto parallasse sull'hover
    function parallax(e, target, layer, label) {
        var layer_coeff = 40 / layer;
        var x = ($(window).width() - target[0].offsetWidth) / 8 - (e.originalEvent.layerX - ($(window).width() / 8)) / layer_coeff;
        var y = ($(window).height() - target[0].offsetHeight) / 8 - (e.originalEvent.layerY - ($(window).height() / 8)) / layer_coeff;

        if (label == 'scritte') y = 0;

        TweenMax.to(target, 2, {
            x: x,
            y: y,
            ease: Power1.easeOut,
            rotation: 0.001 // su alcuni browser l'immagine viene animata in modo leggermente scattoso impostare una rotation ad un valore bassissimo risolve il problema https://greensock.com/forums/topic/11038-jumpy-choppy-css-transform-animations/
        });

        return {
            x: x,
            y: y
        }
    }


    function bmAnimation() {
        var bm = $('.section-buoni-motivi .item');
        var bmtitle = $('.section-buoni-motivi .item').find('.title');

        // closure per gestione animazione elementi interni alla sezione con timeline
        function gsapAnimInview(target) {
            var tl = new TimelineMax();

            //tl.fromTo( $(target), 4.2, { opacity: 0, y: 300 }, { opacity: 1, y: 0, ease: Power4.easeOut });
            //tl.fromTo($(target).find('.image'), 2.6, { x: -10000 }, { x: 0, ease: Power4.fadeIn }, '-=1');
            /*
                        if($(target).hasClass('even')){
            
                            //tl.fromTo($(target).find('.image'), 1.0, { x: +10000 }, { x: 0, ease: Power4.easeOut }, '-=1');
                        }else{
                            tl.fromTo($(target).find('.data'), 2.6, { x: +10000 }, { x: 0, ease: Power4.easeOut }, '-=1');
                            //tl.fromTo($(target).find('.image'), 1.0, { x: -10000 }, { x: 0, ease: Power4.easeOut }, '-=1');
                        }
            */



            return tl;
        }


        // aggancio un'istanza di waypoint per ogni sezione di modo che quando entra in viewport scatti l'animazione
        bm.each(function (key, value) {
            new Waypoint({
                element: $(value),
                initAnimation: false,
                handler: function (direction) {

                    if (!this.initAnimation) gsapAnimInview(value);

                    this.initAnimation = true;
                },
                offset: '70%'
            });
        });


    }

    function highlightAnimation() {
        var highlight = $('.section-servizi .item');
        var highlightTitle = $('.section-servizi .item').find('.title');

        // closure per gestione animazione elementi interni alla sezione con timeline
        function gsapAnimInview(target) {
            var tl = new TimelineMax();

            //tl.fromTo( $(target), 4.2, { opacity: 0, y: 300 }, { opacity: 1, y: 0, ease: Power4.easeOut });

            if ($(target).hasClass('even')) {
                tl.fromTo($(target).find('.data'), 2.6, { x: -10000 }, { x: 0, ease: Power4.easeOut }, '-=1');
                //tl.fromTo($(target).find('.image'), 1.0, { x: +10000 }, { x: 0, ease: Power4.easeOut }, '-=1');
            } else {
                tl.fromTo($(target).find('.data'), 2.6, { x: +10000 }, { x: 0, ease: Power4.easeOut }, '-=1');
                //tl.fromTo($(target).find('.image'), 1.0, { x: -10000 }, { x: 0, ease: Power4.easeOut }, '-=1');
            }



            return tl;
        }


        // aggancio un'istanza di waypoint per ogni sezione di modo che quando entra in viewport scatti l'animazione
        highlight.each(function (key, value) {
            new Waypoint({
                element: $(value),
                initAnimation: false,
                handler: function (direction) {

                    if (!this.initAnimation) gsapAnimInview(value);

                    this.initAnimation = true;
                },
                offset: '70%'
            });
        });
    }



    /**
	 * Funzione per animare l'invito a scorrere
	 */

    function offcanvasForm(button) {

        button.click(function () {
            $(this).find(' + .offcanvas-form').css('left', '0');
        });
    }

    function slideMobile() {

        //var mobileSize;

        if (!$('body').hasClass('is-mobile')) return;

        if (typeof mobileSize == "undefined") {
            return;
        }



        if ($(window).width() > 767) {
            jQuery.each(mobileSize.tablet, function (i, item) {
                $('.header-slideshow .owl-carousel img:eq(' + i + ')').attr('src', item[0])
            });
        } else {
            jQuery.each(mobileSize.mobile, function (i, item) {
                $('.header-slideshow .owl-carousel img:eq(' + i + ')').attr('src', item[0])
            });
        }
    }

    $j(document).ready(function () {

        allClick();
        responsiveTable();
        addImageSizeForPhotoSwiper();
        iframeModalOpen();
        responsiveMenu();
        //toggleForm( 1199 );
        filterElements();
        injectorLinkMaps();
        scrollHeader();
        offcanvasForm($('.efb-item-date-range label'));
        slideMobile();


        if (!$('body').hasClass('is-mobile')) highlightAnimation();
        if (!$('body').hasClass('is-mobile')) bmAnimation();

        $('.offer-side').pin({
            minWidth: 993,
            containerSelector: ".body-offer",
            padding: {
                top: 130,
                bottom: 10
            }
        });


        startOwl('.owl-carousel');
        smoothScroll();

        closePhotoswipe();

        if (typeof lang && lang != 'en') {
            translateCountdown(lang);
        }

        edtCountdown();

        $('.gform_wrapper form').each(function () {

            $(this).checkEditaForm();
        });


        $('.checkNewsletter').each(function () {
            $(this).checkEditaForm();
        });

        var buttonBookingFixed = $('.button-booking-fixed');

        $(window).scroll(function (event) {
            var scroll = $(window).scrollTop();

            // if ( $('body').hasClass('is-mobile') ) {
            //     if ( scroll > 500 ) {
            //         buttonBookingFixed.addClass('open');
            //     } else {
            // 		buttonBookingFixed.removeClass('open');
            //     }

            // }else{

            //     if ( scroll > 900 ) {
            //         buttonBookingFixed.addClass('open');
            //     } else {
            // 		buttonBookingFixed.removeClass('open');
            //     }	            
            // }

            if (!$('body').hasClass('is-mobile')) {
                if (scroll > 900) {
                    buttonBookingFixed.addClass('open');
                } else {
                    buttonBookingFixed.removeClass('open');
                }
            }

        });

        $('.incr-btn').click(function () {
            setTimeout(function () {

                if ($('#efb-379').val() == 0 && !$('#efb-379').is(':disabled')) {
                    $('#item-efb-412').show().find('input').prop('disabled', false);
                } else {
                    $('#item-efb-412').hide().find('input').prop('disabled', true);
                }

                if ($('#efb-380').val() == 0 && !$('#efb-380').is(':disabled')) {
                    $('#item-efb-413').show().find('input').prop('disabled', false);
                } else {
                    $('#item-efb-413').hide().find('input').prop('disabled', true);
                }

                if ($('#efb-381').val() == 0 && !$('#efb-381').is(':disabled')) {
                    $('#item-efb-414').show().find('input').prop('disabled', false);
                } else {
                    $('#item-efb-414').hide().find('input').prop('disabled', true);
                }

            }, 100);
        });

        if ($(window).width() >= 1200) {
            $('.renewal').each(function (index) {
                $(this).hover(
                    function () {
                        $(this).next().fadeIn("fast", "linear");
                    }, function () {
                        $(this).next().fadeOut("fast", "linear");
                    }
                );
            });
        }
        else {
            $('.renewal-tooltip').show();
        }

        $('.page-template-template-camere .item').each(function () {
            if ($(this).hasClass('disabled')) {
                var elem = $(this);

                elem.unbind(allClick())
                    .click(false)
                    .css('cursor', 'default')
                    .removeClass('allclick');
            }
        });

        function startTimer() {
            var presentTime = $('#timer').text();
            var s = presentTime - 1;

            $('#timer').text(s);
            var timer = setTimeout(startTimer, 1000);

            if (s == 0) {
                clearTimeout(timer);
            }
        }

        if (url.renewal) {
            var delay = 10000;
            var first = $(location).attr('pathname');
            var seconds = delay.toString().slice(0, -3);

            first.indexOf(1);
            first.toLowerCase();

            if (url.lingua == 'it') {
                first = first.split("/")[1];
            }
            else {
                first = first.split("/")[2];
            }

            $('#renewalModal').modal({
                backdrop: 'static',
                keyboard: false
            });

            $('#timer').text(seconds);
            startTimer();

            setTimeout(function () {
                if (url.lingua == 'it') {
                    window.location = '/' + first;
                }
                else {
                    window.location = '/' + url.lingua + '/' + first;
                }

            }, delay);
        }
		
		$('#open-menu').click( function() {
			
			$(this).addClass('hide-button');
			
			$('#menu-container').addClass('opened');
			$('#close-menu.mobile-close-menu').addClass('opened');
			$('#content').addClass('blur');
			$('body').addClass('menu-opened');
		});
				
		$('#close-menu').click( function() {
			
			$('#menu-container').removeClass('opened');
			$('#close-menu.mobile-close-menu').removeClass('opened');
			$('#content').removeClass('blur');
			$('body').removeClass('menu-opened');
			
			$('#open-menu').removeClass('hide-button');
		});
		
		$('.read-link').click( function() {
			
			$(this).toggleClass('read-less');
		});
		
    });


    $j(window).load(function () {
        /*
         *	Attraverso il Google Loader carico il modulo mappa
         */
        if ($('body').hasClass('page-template-template-mappa')) {

            $.getScript('https://www.google.com/jsapi', function () {
                if (url.key) {
                    var map_params = 'language=' + url.lingua + '&key=' + url.key;
                }
                else {
                    var map_params = 'language=' + url.lingua;
                }

                google.load('maps', '3', {
                    other_params: map_params + '&libraries=places',
                    callback: function () {
                        initializeGmap();
                    }
                });
            });
        }

        var gallery = $('.gallery');

        if (gallery.length) {
            gallery.each(function (index) {

                initPhotoSwiper("#" + $(this).attr('id') + " .gallery-item");
            });
        }

        initPhotoSwiper(".entry-content .single-image");

        $('.section-form .form-group').equalHeights();
        $('.offersDetails').equalHeights();
        $('.countdown').equalHeights();

        $('.rooms-list .title').equalHeights();

        if (!$('body').hasClass('is-mobile')) {
            $('.buoni-motivi-wrapper .item .title').equalHeights();
        }

        headerAnimation();

    });

    $j(window).resize(function () {



    });

})(jQuery);
